import React from 'react';
import { useIntl } from 'react-intl-next';

import { AISummarizeButtonPlaceholder } from '@atlassian/ai-summary';

import { LoadableLazy, LoadableAfterPaint } from '@confluence/loadable';

import { i18n } from './PageCatchupPopupComponent';

export { PageCatchupPopupForPageHistory } from './PageCatchupPopupForPageHistory';

const AISummarizeButtonPlaceholderWithText = () => {
	const intl = useIntl();
	return (
		<AISummarizeButtonPlaceholder summarizeText={intl.formatMessage(i18n.catchUpButtonText)} />
	);
};

export const PageCatchupComponent = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageCatchupComponent" */ './PageCatchupWrapperComponent'
			)
		).PageCatchupComponentWithErrorBoundary,
});

export const PageCatchupPopup = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageCatchupComponent" */ './PageCatchupPopupComponent'
			)
		).PageCatchupPopupComponent,
	loading: () => <AISummarizeButtonPlaceholderWithText />,
});
