import type { FC } from 'react';
import React from 'react';

import { Box, xcss, Inline, Text, Stack } from '@atlaskit/primitives';
import Issue16Icon from '@atlaskit/icon-object/glyph/issue/16';

import { markdownPlusToAdf } from '@atlassian/adf-markdown-transformer';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown';

import { ADFRenderer } from '@confluence/adf-renderer';

import type { Issue } from './useFetchIssues';

type SingleIssueComponentProps = {
	issue: Issue;
	pageIdMap?: IDMap;
};

const cardStyles = xcss({
	backgroundColor: 'elevation.surface.raised',
	boxShadow: 'elevation.shadow.raised',
	borderRadius: '4px',
	padding: 'space.200',
	margin: 'space.025',
});

export const SingleIssueComponent: FC<SingleIssueComponentProps> = ({ issue, pageIdMap }) => {
	const { issue_type, field_values } = issue;
	const { Summary, Description } = field_values;

	const descriptionAsAdf = markdownPlusToAdf({
		markdown: Description,
		idMap: pageIdMap,
	});

	return (
		<Box xcss={cardStyles}>
			<Stack space="space.100">
				<Inline alignBlock="center" space="space.050">
					<Issue16Icon label="" />
					<Text weight="medium">{issue_type}</Text>
				</Inline>
				<Inline alignBlock="center" space="space.100">
					<Text weight="medium" size="large">
						{Summary}
					</Text>
				</Inline>
				<Text>
					<ADFRenderer document={descriptionAsAdf} />
				</Text>
			</Stack>
		</Box>
	);
};
