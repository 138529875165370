import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';

import { QuickSummaryIcon } from '@confluence/quick-summary';

import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';

const i18nBase = defineMessages({
	buttonLabel: {
		id: 'ai-comments-summary.footer-comments-summary-button-placeholder.label.text',
		defaultMessage: 'Summarize page comments',
		description: 'Label text for the comments summary button placeholder in the footer',
	},
});

const i18nLiveDocs = defineMessages({
	buttonLabel: {
		id: 'ai-comments-summary.footer-comments-summary-button-placeholder.label.text.livedocs',
		defaultMessage: 'Summarize general comments',
		description:
			'Updated version of ai-comments-summary.footer-comments-summary-button-placeholder.label.text',
	},
});

const i18n = mergeLiveDocI18n(i18nBase, i18nLiveDocs);

export const FooterCommentsSummaryButtonPlaceholder = () => {
	const intl = useIntl();

	return (
		<Button appearance="subtle" isDisabled iconBefore={<QuickSummaryIcon label="" />}>
			{intl.formatMessage(i18n.buttonLabel)}
		</Button>
	);
};
