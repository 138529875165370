export function mapToFormattedDate(ISOdate: string | null | undefined, locale: string) {
	if (!ISOdate) return;

	const dateOptions: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};

	return new Date(ISOdate).toLocaleDateString(locale, dateOptions);
}
