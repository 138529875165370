/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CommentsType {
  FOOTER = "FOOTER",
  INLINE = "INLINE",
}

export enum SummaryType {
  BLOGPOST = "BLOGPOST",
  PAGE = "PAGE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CommentsSummaryQuery
// ====================================================

export interface CommentsSummaryQuery_getCommentsSummary {
  contentId: string;
  summary: string;
  summaryId: string;
  language: string;
}

export interface CommentsSummaryQuery {
  getCommentsSummary: CommentsSummaryQuery_getCommentsSummary | null;
}

export interface CommentsSummaryQueryVariables {
  contentId: string;
  contentType: SummaryType;
  commentsType: CommentsType;
}
