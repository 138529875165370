import type { ErrorMessage } from '@atlassian/ai-summary';
import type { ConvoAIErrorMessage } from '@atlassian/ai-agent-streaming';

export enum AIGQLErrors {
	NOT_ENOUGH_CONTENT = 'NOT_ENOUGH_CONTENT',
	TOO_MUCH_CONTENT = 'TOO_MUCH_CONTENT',
	TOO_MANY_REQUEST = 'RATE_LIMIT',
	AUP_VIOLATION = 'AUP_VIOLATION',
	GENERAL_ERROR = 'GENERAL_ERROR',
}

export type AIErrorType = `${ErrorMessage}` | `${AIGQLErrors}` | `${ConvoAIErrorMessage}`;

export enum AIErrorLinkType {
	Troubleshooting = 'Troubleshooting',
	RateLimit = 'RateLimit',
	AupViolation = 'AupViolation',
}

export enum ErrorMessageKeys {
	GENERAL_ERROR = 'generalError',
	NOT_ENOUGH_CONTENT = 'notEnoughContent',
	TOO_MUCH_CONTENT = 'tooMuchContent',
	RATE_LIMIT = 'rateLimit',
	AUP_VIOLATION = 'aupViolation',
}

export enum ConvoAIErrorMessageMap {
	RATE_LIMIT = ErrorMessageKeys.RATE_LIMIT,
	ANSWER_UNAVAILABLE_FOR_CONTENT = ErrorMessageKeys.NOT_ENOUGH_CONTENT,
	ACCEPTABLE_USE_VIOLATIONS = ErrorMessageKeys.AUP_VIOLATION,
	BAD_REQUEST = ErrorMessageKeys.GENERAL_ERROR,
	UNEXPECTED = ErrorMessageKeys.GENERAL_ERROR,
	TIMEOUT = ErrorMessageKeys.GENERAL_ERROR,
	NOT_ENOUGH_CONTENT_ERROR = ErrorMessageKeys.NOT_ENOUGH_CONTENT,
}

enum BaseErrorMessageMap {
	GENERAL_ERROR = ErrorMessageKeys.GENERAL_ERROR,
	NETWORK_ERROR = ErrorMessageKeys.GENERAL_ERROR,
	NO_ANSWER = ErrorMessageKeys.GENERAL_ERROR,
	NO_AGENT = ErrorMessageKeys.GENERAL_ERROR,
	PLUGIN_ERRORED = ErrorMessageKeys.GENERAL_ERROR,
	AI_DISABLED = ErrorMessageKeys.GENERAL_ERROR,
	OPENAI_RATE_LIMIT = ErrorMessageKeys.RATE_LIMIT,
	AUP_VIOLATION = ErrorMessageKeys.AUP_VIOLATION,
	NOT_ENOUGH_CONTENT = ErrorMessageKeys.NOT_ENOUGH_CONTENT,
	// typo comes from the backend
	// https://bitbucket.org/atlassian/assistance-service/src/02087c3b6569f042ec338e08e8e0e152c4563dad/src/schema/message_publisher.py#lines-74
	ASWER_UNAVAILABLE_FOR_CONTENT = ErrorMessageKeys.NOT_ENOUGH_CONTENT,
	TOO_MUCH_CONTENT = ErrorMessageKeys.TOO_MUCH_CONTENT,
	NOT_FOUND = ErrorMessageKeys.GENERAL_ERROR,
	INTERNAL_SERVER_ERROR = ErrorMessageKeys.GENERAL_ERROR,
}

export const ErrorTypeErrorMessageMap = { ...ConvoAIErrorMessageMap, ...BaseErrorMessageMap };

export enum ErrorMessageKeyErrorLinkMap {
	generalError = AIErrorLinkType.Troubleshooting,
	rateLimit = AIErrorLinkType.RateLimit,
	aupViolation = AIErrorLinkType.AupViolation,
	notEnoughContent = AIErrorLinkType.Troubleshooting,
	tooMuchContent = AIErrorLinkType.Troubleshooting,
}
