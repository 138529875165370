import { useState, useEffect, useCallback } from 'react';

export const useInlineLinkingStreaming = (streamingInterval = 50, batchSize = 3) => {
	const [_, setBuffer] = useState<string[]>([]);
	const [displayedContent, setDisplayedContent] = useState<string>('');
	const [lastProcessedLength, setLastProcessedLength] = useState<number>(0);

	const updateBuffer = useCallback((newContent: string) => {
		setBuffer((prev) => [...prev, ...newContent.split('')]);
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setBuffer((prevBuffer) => {
				if (prevBuffer.length > 0) {
					const contentToProcess = prevBuffer.slice(0, batchSize).join('');
					let updatedBatchSize = batchSize;
					if (contentToProcess.includes('<')) {
						let closingBraceCount = 0;
						let i = 0;
						for (; i < prevBuffer.length; i++) {
							if (prevBuffer[i] === '>') {
								if (closingBraceCount === 1) {
									break;
								}
								closingBraceCount += 1;
							}
						}
						updatedBatchSize = i;
					}
					setDisplayedContent(
						(prevContent) => prevContent + prevBuffer.slice(0, updatedBatchSize).join(''),
					);
					return prevBuffer.slice(updatedBatchSize);
				}
				return prevBuffer;
			});
		}, streamingInterval);
		return () => clearInterval(interval);
	}, [batchSize, streamingInterval]);

	return { displayedContent, updateBuffer, lastProcessedLength, setLastProcessedLength };
};
