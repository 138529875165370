import type { Editor } from '../platform-ui/EditorsNameListComponent';
import type { PageCatchupMetadataQuery_catchupEditMetadataForContent_users as UserFromEditQuery } from '../__types__/PageCatchupMetadataQuery';
import type { PageCatchupVersionSummaryMetadataForContentQuery_catchupVersionSummaryMetadataForContent_versionSummaryMetadata_users as UsersFromVersionQuery } from '../__types__/PageCatchupVersionSummaryMetadataForContentQuery';

export function mapToEditors(
	editors: (UserFromEditQuery | UsersFromVersionQuery | null)[],
): Editor[] {
	return editors.map((u) => {
		return {
			name: u?.displayName ?? '',
			src: u && 'profilePicture' in u ? u?.profilePicture?.path : '',
		};
	});
}
