/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CatchupContentType {
  BLOGPOST = "BLOGPOST",
  PAGE = "PAGE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PageCatchupMetadataQuery
// ====================================================

export interface PageCatchupMetadataQuery_catchupEditMetadataForContent_users {
  displayName: string | null;
}

export interface PageCatchupMetadataQuery_catchupEditMetadataForContent {
  lastVisitTimeISO: string | null;
  hasVersionChangedSinceLastVisit: boolean | null;
  users: (PageCatchupMetadataQuery_catchupEditMetadataForContent_users | null)[] | null;
}

export interface PageCatchupMetadataQuery_content_nodes_version {
  number: number | null;
}

export interface PageCatchupMetadataQuery_content_nodes {
  version: PageCatchupMetadataQuery_content_nodes_version | null;
}

export interface PageCatchupMetadataQuery_content {
  nodes: (PageCatchupMetadataQuery_content_nodes | null)[] | null;
}

export interface PageCatchupMetadataQuery {
  catchupEditMetadataForContent: PageCatchupMetadataQuery_catchupEditMetadataForContent | null;
  content: PageCatchupMetadataQuery_content | null;
}

export interface PageCatchupMetadataQueryVariables {
  contentId: string;
  contentType: CatchupContentType;
  endTimeMs: any;
}
