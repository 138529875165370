import { LoadableLazy } from '@confluence/loadable';

export const AIPresentationModalWrapper = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AIPresentationGenerationModal" */ './AIPresentationModalWrapper'
			)
		).AIPresentationModalWrapperWithErrorBoundary,
});
