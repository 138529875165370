import gql from 'graphql-tag';

export const PageCatchupMetadataQuery = gql`
	query PageCatchupMetadataQuery(
		$contentId: ID!
		$contentType: CatchupContentType!
		$endTimeMs: Long!
	) {
		catchupEditMetadataForContent(
			contentId: $contentId
			contentType: $contentType
			endTimeMs: $endTimeMs
		) {
			lastVisitTimeISO
			hasVersionChangedSinceLastVisit
			users {
				displayName
				profilePicture {
					path
				}
			}
		}
		content(id: $contentId) {
			nodes {
				version {
					number
				}
			}
		}
	}
`;
