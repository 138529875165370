import React, { type FC, type ReactNode } from 'react';
import { defineMessages, type MessageDescriptor, useIntl } from 'react-intl-next';

import type { BoxProps } from '@atlaskit/primitives';
import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { AIErrorLinkType } from '../__types__/aiErrors';

const textStyles = xcss({
	color: 'color.text.subtlest',
});

export const TROUBLE_SHOOTING_DOCS_URL =
	'https://support.atlassian.com/organization-administration/docs/troubleshooting-for-atlassian-intelligence/';
export const USAGE_LIMITS_DOCS_URL =
	'https://support.atlassian.com/organization-administration/docs/excessive-use-in-atlassian-intelligence/';
export const ACCEPTABLE_USE_POLICY_URL = 'https://www.atlassian.com/legal/acceptable-use-policy';

export const GenericErrorMessages: Record<string, MessageDescriptor> = defineMessages({
	generalError: {
		id: 'ai-common.common-error.general-error',
		defaultMessage:
			'Atlassian Intelligence can’t provide a response right now. Read more <a>in our support documentation</a>.',
		description: 'general error message when quick summary fails',
	},
	notEnoughContent: {
		id: 'ai-common.common-error.not-enough-content',
		defaultMessage:
			'There isn’t enough content here to summarize. Try again when more content has been added.',
		description: 'error message when there is not enough content to summarize',
	},
	rateLimit: {
		id: 'ai-common.common-error.rate-limit',
		defaultMessage:
			'Excessive use has been detected with Atlassian Intelligence. Try again later. Read more <a>in our support documentation</a>.',
		description: 'error message when the user has hit the rate limit of quick summary',
	},
});

const LINK_MAP: { [key: string]: string } = {
	[AIErrorLinkType.RateLimit]: USAGE_LIMITS_DOCS_URL,
	[AIErrorLinkType.AupViolation]: ACCEPTABLE_USE_POLICY_URL,
	[AIErrorLinkType.Troubleshooting]: TROUBLE_SHOOTING_DOCS_URL,
};

type AIErrorTextProps = BoxProps<'p'> & {
	errorMessage: MessageDescriptor;
	linkType?: AIErrorLinkType;
	source: string;
	analyticsAttributes?: Record<string, any>;
};

export const AIErrorText: FC<AIErrorTextProps> = ({
	errorMessage,
	linkType = AIErrorLinkType.Troubleshooting,
	source,
	analyticsAttributes,
	xcss,
	...props
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onDocsLinkClicked = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source,
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: `atlassianIntelligence${linkType}DocsLink`,
				...(analyticsAttributes ? { attributes: analyticsAttributes } : {}),
			},
		}).fire();
	};

	// This is safe for messages without link replacements also.
	// formatMessage ignores replacements when none exist in the string
	const resolvedMessage = formatMessage(errorMessage, {
		a: (chunks: ReactNode[]) => (
			<a href={LINK_MAP[linkType]} target="_blank" onClick={onDocsLinkClicked}>
				{chunks}
			</a>
		),
	});

	return (
		<Box xcss={xcss || textStyles} {...props}>
			{resolvedMessage}
		</Box>
	);
};
