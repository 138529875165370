import { createSingleQueryParamHook } from '@confluence/route-manager';
import { CONTENT_HISTORY, CONTEXT_PATH } from '@confluence/named-routes';

type GetVersionHistoryLinkResult = {
	versionHistoryLink: string;
};

const usePrevVersionParam = createSingleQueryParamHook('prevVersion');
const useCurrentVersionParam = createSingleQueryParamHook('currVersion');

export const useGetVersionHistoryLink = (
	contentId: string,
	spaceKey: string,
	pageVersion: number,
): GetVersionHistoryLinkResult => {
	const fallbackLink = CONTENT_HISTORY.toUrl({
		spaceKey,
		contentId,
	});

	const prevVersionFromParam = parseInt(usePrevVersionParam() ?? '');
	const currVersionFromParam = parseInt(useCurrentVersionParam() ?? '');

	if (pageVersion < 0 && (isNaN(currVersionFromParam) || isNaN(prevVersionFromParam))) {
		return {
			versionHistoryLink: fallbackLink,
		};
	}

	const currVersion = isNaN(currVersionFromParam) ? pageVersion : currVersionFromParam;
	const prevVersion = isNaN(prevVersionFromParam) ? pageVersion - 1 : prevVersionFromParam;

	const versionDiffLink = `${CONTEXT_PATH}/pages/diffpagesbyversion.action?pageId=${contentId}&selectedPageVersions=${prevVersion}&selectedPageVersions=${currVersion}`;

	return {
		versionHistoryLink: versionDiffLink,
	};
};
