/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { CommentsType } from './__types__/useCombinedCommentsSummariesQueriesCommentsSummaryQuery';
import type {
	SummaryType,
	useCombinedCommentsSummariesQueriesCommentsSummaryQuery as useCombinedCommentsSummariesQueriesCommentsSummaryQuery$data,
	useCombinedCommentsSummariesQueriesCommentsSummaryQueryVariables as useCombinedCommentsSummariesQueriesCommentsSummaryQuery$variables,
} from './__types__/useCombinedCommentsSummariesQueriesCommentsSummaryQuery';
export type CombinedCommentsSummariesData = {
	footerCommentsSummaryData:
		| useCombinedCommentsSummariesQueriesCommentsSummaryQuery$data
		| undefined;
	inlineCommentsSummaryData:
		| useCombinedCommentsSummariesQueriesCommentsSummaryQuery$data
		| undefined;
};

export type CombinedCommentsSummariesErrors = {
	footerCommentsSummaryError: ApolloError | undefined;
	inlineCommentsSummaryError: ApolloError | undefined;
};

type CombinedCommentsQueriesSummariesProps = {
	contentId: string;
	contentType: SummaryType;
	shouldSkipFooterCommentsQuery?: boolean;
	shouldSkipInlineCommentsQuery?: boolean;
};

type CombinedCommentsSummariesQueries = {
	data: CombinedCommentsSummariesData;
	errors: CombinedCommentsSummariesErrors;
	loading: boolean;
	refetch: () => void;
};

export const useCombinedCommentsSummariesQueries = ({
	contentId,
	contentType,
	shouldSkipFooterCommentsQuery = false,
	shouldSkipInlineCommentsQuery = false,
}: CombinedCommentsQueriesSummariesProps): CombinedCommentsSummariesQueries => {
	const {
		data: footerCommentsSummaryData,
		loading: footerCommentsQueryLoading,
		error: footerCommentsSummaryError,
		refetch: footerCommentsRefetch,
	} = useQuery<
		useCombinedCommentsSummariesQueriesCommentsSummaryQuery$data,
		useCombinedCommentsSummariesQueriesCommentsSummaryQuery$variables
	>(
		gql`
			query useCombinedCommentsSummariesQueriesCommentsSummaryQuery(
				$contentId: ID!
				$contentType: SummaryType!
				$commentsType: CommentsType!
			) {
				getCommentsSummary(
					contentId: $contentId
					contentType: $contentType
					commentsType: $commentsType
				) {
					contentId
					summary
					summaryId
					language
				}
			}
		`,
		{
			variables: {
				contentId,
				contentType,
				commentsType: CommentsType.FOOTER,
			},
			notifyOnNetworkStatusChange: true,
			skip: shouldSkipFooterCommentsQuery,
		},
	);

	const {
		data: inlineCommentsSummaryData,
		loading: inlineCommentsQueryLoading,
		error: inlineCommentsSummaryError,
		refetch: inlineCommentsRefetch,
	} = useQuery<
		useCombinedCommentsSummariesQueriesCommentsSummaryQuery$data,
		useCombinedCommentsSummariesQueriesCommentsSummaryQuery$variables
	>(
		gql`
			query useCombinedCommentsSummariesQueriesCommentsSummaryQuery(
				$contentId: ID!
				$contentType: SummaryType!
				$commentsType: CommentsType!
			) {
				getCommentsSummary(
					contentId: $contentId
					contentType: $contentType
					commentsType: $commentsType
				) {
					contentId
					summary
					summaryId
					language
				}
			}
		`,
		{
			variables: {
				contentId,
				contentType,
				commentsType: CommentsType.INLINE,
			},
			notifyOnNetworkStatusChange: true,
			skip: shouldSkipInlineCommentsQuery,
		},
	);

	const refetch = () => {
		void footerCommentsRefetch();
		void inlineCommentsRefetch();
	};

	return {
		data: {
			footerCommentsSummaryData,
			inlineCommentsSummaryData,
		},
		loading: footerCommentsQueryLoading || inlineCommentsQueryLoading,
		errors: {
			footerCommentsSummaryError,
			inlineCommentsSummaryError,
		},
		refetch,
	};
};
