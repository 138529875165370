import { cfetch } from '@confluence/network';

const AI_MATE_URL = '/gateway/api/assist/chat/v1/invoke_agent';

type FetchSuggestedIssuesProps = {
	cloudId: string;
	markdownPlus: string;
};

export const fetchSuggestedIssues = async ({
	cloudId,
	markdownPlus,
}: FetchSuggestedIssuesProps) => {
	const response = await cfetch(AI_MATE_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Experience-Id': 'ai-issue-create-confluence-bulk',
			'X-Product': 'confluence',
			'Atl-CloudId': cloudId,
		},
		body: JSON.stringify({
			recipient_agent_named_id: 'ai_issue_create_agent',
			agent_input_context: {
				application: 'Confluence',
				context: {
					confluence_page_markdown_plus: markdownPlus,
				},
				suggested_issues_config: {
					max_issues: 10,
					suggested_issue_field_types: [
						{
							issue_type: 'Task',
							fields: [
								{
									field_name: 'Summary',
									field_type: 'Short-Text',
								},
								{
									field_name: 'Description',
									field_type: 'Paragraph',
								},
							],
						},
					],
				},
			},
		}),
	});

	const data = await response.json();
	const content = data?.message?.content;
	if (!content) {
		return undefined;
	}
	return JSON.parse(content);
};
