export enum CommentsSummaryAnalyticsType {
	FOOTER = 'FOOTER',
	INLINE = 'INLINE',
}

export type SummarySourceType =
	| 'quickSummary'
	| 'commentsSummary'
	| 'pageCatchup'
	| 'aiPresentationGeneration'
	| 'aiSnippet'
	| 'confluenceHighlightIssueCreate'
	| 'bulkIssueCreate'
	| 'aiAudio';

export type SummaryAnalyticsAttributesType = {
	commentsType?: CommentsSummaryAnalyticsType | CommentsSummaryAnalyticsType[];
	contentLastUpdated?: number;
	contentType?: string;
	[key: string]: any;
};

export type SummaryAnalyticsPropertiesType = {
	source: SummarySourceType;
	additionalAnalyticsAttributes?: SummaryAnalyticsAttributesType;
};
