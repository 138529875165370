import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { AIAttributionFooter } from '@atlassian/ai-components';

const footerBoxStyles = xcss({
	borderTop: `1px solid ${token('color.border')}`,
});

const boxTextStyles = xcss({
	color: 'color.text.subtlest',
});

const i18n = defineMessages({
	diffEmptyBetweenVersionsText: {
		id: 'page-catchup.diff.empty.between.versions.text',
		defaultMessage:
			'These versions are too similar for Atlassian Intelligence (AI) to generate a summary of the differences between them.',
		description:
			'Text to show to the user to indicate that the two selected version are too similar to generate a summary',
	},
});

export const VersionDiffEmptyComponent = () => {
	return (
		<Stack>
			<Box padding="space.250" xcss={boxTextStyles}>
				<FormattedMessage {...i18n.diffEmptyBetweenVersionsText} />
			</Box>
			<Box paddingInline="space.200" paddingBlock="space.050" xcss={footerBoxStyles}>
				<AIAttributionFooter />
			</Box>
		</Stack>
	);
};
