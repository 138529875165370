import gql from 'graphql-tag';

export const PageCatchupVersionDiffMetadataForContentQuery = gql`
	query PageCatchupVersionDiffMetadataForContentQuery(
		$contentId: ID!
		$contentType: CatchupContentType!
		$originalContentVersion: Int!
		$revisedContentVersion: Int!
	) {
		catchupVersionDiffMetadataForContent(
			contentId: $contentId
			contentType: $contentType
			originalContentVersion: $originalContentVersion
			revisedContentVersion: $revisedContentVersion
		) {
			isDiffEmpty
			users {
				displayName
				profilePicture {
					height
					isDefault
					width
					path
				}
			}
		}
	}
`;
