// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';
import React, { useContext, useEffect } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages, useIntl } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';
import type { FC } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss, Text } from '@atlaskit/primitives';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { ErrorDisplay } from '@confluence/error-boundary';
import {
	HandledErrorType,
	TROUBLE_SHOOTING_DOCS_URL,
	USAGE_LIMITS_DOCS_URL,
	ACCEPTABLE_USE_POLICY_URL,
} from '@confluence/quick-summary';
import { CommentCountsContext } from '@confluence/comment-context';

import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';

import { useCommentsSummaryErrorHandler } from './hooks/useCommentsSummaryErrorHandler';
import type { CommentsSummarySource } from './CommentsSummaryContent';

const textStyles = xcss({
	color: 'color.text.subtlest',
	font: token('font.body'),
});

interface Messages {
	[key: string]: MessageDescriptor;
}

const i18nBase: Messages = defineMessages({
	combinedErrorGeneral: {
		id: 'ai-comments-summary.comments-summaries-error.combined-error-general',
		defaultMessage:
			'Atlassian Intelligence can’t provide a summary right now. You can try again later or read more <a>in our support documentation</a>.',
		description:
			'The error that displays in the comments summary component when both page and inline comments fail with a general error',
	},
	combinedErrorTooMuchContent: {
		id: 'ai-comments-summary.comments-summaries-error.combined-error-too-much-content',
		defaultMessage:
			'There are too many comments for Atlassian Intelligence (AI) to summarize. Try again on content with fewer comments.',
		description:
			'The error that displays in the comments summary component when both page and inline comments fail with a too much content error',
	},
	combinedErrorNotEnoughContent: {
		id: 'ai-comments-summary.comments-summaries-error.combined-error-too-little-content',
		defaultMessage:
			'There aren’t enough comments for Atlassian Intelligence (AI) to summarize. Try again when more comments have been added.',
		description:
			'The error that displays in the comments summary component when both page and inline comments fails with a too little content error',
	},
	combinedErrorRateLimit: {
		id: 'ai-comments-summary.comments-summaries-error.combined-error-rate-limit',
		defaultMessage:
			'We’ve received too many recent requests for Atlassian Intelligence (AI). Try again in a few minutes or <a>read about excessive use of AI</a>.',
		description:
			'The error that displays in the comments summary component when both page and inline comments fail with a rate limit error',
	},
	combinedErrorAUPViolation: {
		id: 'ai-comments-summary.comments-summaries-error.combined-error-aup-violation',
		defaultMessage:
			'Atlassian Intelligence (AI) can’t provide a summary of the comments on this content, as some comments may not comply with our <a>Acceptable Use Policy</a>.',
		description:
			'The error that displays in the comments summary component when both page and inline comments fail with an aup violation',
	},
	footerCommentsErrorGeneral: {
		id: 'ai-comments-summary.comments-summaries-error.footer-comments-error-general',
		defaultMessage:
			'There is no summary of page comments at this time. You can try again later or read more <a>in our support documentation</a>.',
		description:
			'The error that shows after the inline comments summary when page comments errors with a general error',
	},
	footerCommentsErrorTooMuchContent: {
		id: 'ai-comments-summary.comments-summaries-error.footer-comments-error-too-much-content',
		defaultMessage:
			'This summary only includes inline comments, as there are too many page comments for Atlassian Intelligence (AI) to summarize.',
		description:
			'The error that shows after the inline comments summary when page comments errors with a too much content error',
	},
	footerCommentsErrorNotEnoughContent: {
		id: 'ai-comments-summary.comments-summaries-error.footer-comments-error-too-little-content',
		defaultMessage:
			'This summary only includes inline comments, as there aren’t enough page comments for Atlassian Intelligence (AI) to summarize.',
		description:
			'The error that shows after the inline comments summary when page comments errors with a too little content error',
	},
	footerCommentsErrorRateLimit: {
		id: 'ai-comments-summary.comments-summaries-error.footer-comments-error-rate-limit',
		defaultMessage:
			'This summary only includes inline comments, as we’ve received too many page comment summary requests for Atlassian Intelligence (AI). Try again in a few minutes or <a>read about excessive use of AI</a>.',
		description:
			'The error that shows after the inline comments summary when page comments errors with a rate limit error',
	},
	footerCommentsErrorAUPViolation: {
		id: 'ai-comments-summary.comments-summaries-error.footer-comments-error-aup-violation',
		defaultMessage:
			'This summary only includes inline comments, as some page comments may not comply with Atlassian’s <a>Acceptable Use Policy</a>.',
		description:
			'The error that shows after the inline comments summary when page comments errors with an aup violation error',
	},
	inlineCommentsErrorGeneral: {
		id: 'ai-comments-summary.comments-summaries-error.inline-comments-error-general',
		defaultMessage:
			'There is no summary of inline comments at this time. You can try again later or read more <a>in our support documentation</a>.',
		description:
			'The error that shows after the page comments summary when inline comments errors with a general error',
	},
	inlineCommentsErrorTooMuchContent: {
		id: 'ai-comments-summary.comments-summaries-error.inline-comments-error-too-much-content',
		defaultMessage:
			'This summary only includes page comments, as there are too many inline comments for Atlassian Intelligence (AI) to summarize.',
		description:
			'The error that shows after the page comments summary when inline comments errors with a too much content error',
	},
	inlineCommentsErrorNotEnoughContent: {
		id: 'ai-comments-summary.comments-summaries-error.inline-error-too-little-content',
		defaultMessage:
			'This summary only includes page comments, as there aren’t enough inline comments for Atlassian Intelligence (AI) to summarize.',
		description:
			'The error that shows after the page comments summary when inline comments errors with a too little content error',
	},
	inlineCommentsErrorRateLimit: {
		id: 'ai-comments-summary.comments-summaries-error.inline-comments-error-rate-limit',
		defaultMessage:
			'This summary only includes page comments, as we’ve received too many inline comment summary requests for Atlassian Intelligence (AI). Try again in a few minutes or <a>read about excessive use of AI</a>.',
		description:
			'The error that shows after the page comments summary when inline comments errors with a rate limit error',
	},
	inlineCommentsErrorAUPViolation: {
		id: 'ai-comments-summary.comments-summaries-error.inline-comments-error-aup-violation',
		defaultMessage:
			'This summary only includes page comments, as some inline comments may not comply with Atlassian’s <a>Acceptable Use Policy</a>.',
		description:
			'The error that shows after the page comments summary when inline comments errors with an aup violation error',
	},
});

const i18nLiveDocs = defineMessages({
	footerCommentsErrorGeneral: {
		id: 'ai-comments-summary.comments-summaries-error.footer-comments-error-general.livedocs',
		defaultMessage:
			'There is no summary of general comments at this time. You can try again later or read more <a>in our support documentation</a>.',
		description:
			'Updated version of ai-comments-summary.comments-summaries-error.footer-comments-error-general',
	},

	footerCommentsErrorTooMuchContent: {
		id: 'ai-comments-summary.comments-summaries-error.footer-comments-error-too-much-content.livedocs',
		defaultMessage:
			'This summary only includes inline comments, as there are too many general comments for Atlassian Intelligence (AI) to summarize.',
		description:
			'Updated version of ai-comments-summary.comments-summaries-error.footer-comments-error-too-much-content',
	},

	footerCommentsErrorNotEnoughContent: {
		id: 'ai-comments-summary.comments-summaries-error.footer-comments-error-too-little-content.livedocs',
		defaultMessage:
			'This summary only includes inline comments, as there aren’t enough general comments for Atlassian Intelligence (AI) to summarize.',
		description:
			'Updated version of ai-comments-summary.comments-summaries-error.footer-comments-error-too-little-content',
	},

	footerCommentsErrorRateLimit: {
		id: 'ai-comments-summary.comments-summaries-error.footer-comments-error-rate-limit.livedocs',
		defaultMessage:
			'This summary only includes inline comments, as we’ve received too many general comment summary requests for Atlassian Intelligence (AI). Try again in a few minutes or <a>read about excessive use of AI</a>.',
		description:
			'Updated version of ai-comments-summary.comments-summaries-error.footer-comments-error-rate-limit',
	},

	footerCommentsErrorAUPViolation: {
		id: 'ai-comments-summary.comments-summaries-error.footer-comments-error-aup-violation.livedocs',
		defaultMessage:
			'This summary only includes inline comments, as some general comments may not comply with Atlassian’s <a>Acceptable Use Policy</a>.',
		description:
			'Updated version of ai-comments-summary.comments-summaries-error.footer-comments-error-aup-violation',
	},

	inlineCommentsErrorTooMuchContent: {
		id: 'ai-comments-summary.comments-summaries-error.inline-comments-error-too-much-content.livedocs',
		defaultMessage:
			'This summary only includes general comments, as there are too many inline comments for Atlassian Intelligence (AI) to summarize.',
		description:
			'Updated version of ai-comments-summary.comments-summaries-error.inline-comments-error-too-much-content',
	},

	inlineCommentsErrorNotEnoughContent: {
		id: 'ai-comments-summary.comments-summaries-error.inline-error-too-little-content.livedocs',
		defaultMessage:
			'This summary only includes general comments, as there aren’t enough inline comments for Atlassian Intelligence (AI) to summarize.',
		description:
			'Updated version of ai-comments-summary.comments-summaries-error.inline-error-too-little-content',
	},

	inlineCommentsErrorRateLimit: {
		id: 'ai-comments-summary.comments-summaries-error.inline-comments-error-rate-limit.livedocs',
		defaultMessage:
			'This summary only includes general comments, as we’ve received too many inline comment summary requests for Atlassian Intelligence (AI). Try again in a few minutes or <a>read about excessive use of AI</a>.',
		description:
			'Updated version of ai-comments-summary.comments-summaries-error.inline-comments-error-rate-limit',
	},

	inlineCommentsErrorAUPViolation: {
		id: 'ai-comments-summary.comments-summaries-error.inline-comments-error-aup-violation.livedocs',
		defaultMessage:
			'This summary only includes general comments, as some inline comments may not comply with Atlassian’s <a>Acceptable Use Policy</a>.',
		description:
			'Updated version of ai-comments-summary.comments-summaries-error.inline-comments-error-aup-violation',
	},
});

const i18n = mergeLiveDocI18n(i18nBase, i18nLiveDocs);

const enum i18nPrefixes {
	Combined = 'combined',
	Footer = 'footerComments',
	Inline = 'inlineComments',
}

type CommentsSummariesErrorsProps = {
	contentId: string;
	footerCommentsError?: ApolloError;
	inlineCommentsError?: ApolloError;
	source: CommentsSummarySource;
};

export const CommentsSummariesError: FC<CommentsSummariesErrorsProps> = ({
	contentId,
	footerCommentsError,
	inlineCommentsError,
	source,
}) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { trackAIResultError } = useAIEventsInstrumentation();
	const {
		erredQueries,
		errorType,
		shouldCallErrorDisplayForFooterComments,
		shouldCallErrorDisplayForInlineComments,
	} = useCommentsSummaryErrorHandler(contentId, source, footerCommentsError, inlineCommentsError);
	const { footerCommentsCount, unresolvedInlineCommentsCount } = useContext(CommentCountsContext);

	useEffect(() => {
		trackAIResultError(
			{
				aiErrorMessage: errorType,
				// TODO Ideally we should pass the real code from the response
				aiErrorCode: 400,
			},
			{
				attributes: {
					contentId,
					errorType,
					commentsType: erredQueries,
					source,
				},
			},
		);
	}, [erredQueries, contentId, trackAIResultError, errorType, source]);

	const onDocsLinkClicked = (linkName: string) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'commentsSummaryError',
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: `atlassianIntelligence${linkName}DocsLink`,
				attributes: {
					contentId,
					errorType,
					commentsType: erredQueries,
					source,
				},
			},
		}).fire();
	};

	const errorMessage = () => {
		let i18nPrefix = i18nPrefixes.Combined;
		if (footerCommentsError && !inlineCommentsError && unresolvedInlineCommentsCount > 0)
			i18nPrefix = i18nPrefixes.Footer;
		if (inlineCommentsError && !footerCommentsError && footerCommentsCount > 0)
			i18nPrefix = i18nPrefixes.Inline;

		switch (errorType) {
			case HandledErrorType.TooMuchContent:
				return intl.formatMessage(i18n[`${i18nPrefix}ErrorTooMuchContent`]);
			case HandledErrorType.NotEnoughContent:
				return intl.formatMessage(i18n[`${i18nPrefix}ErrorNotEnoughContent`]);
			case HandledErrorType.AUPViolation:
				return intl.formatMessage(i18n[`${i18nPrefix}ErrorAUPViolation`], {
					a: (chunks: React.ReactNode[]) => (
						<a
							href={ACCEPTABLE_USE_POLICY_URL}
							target="_blank"
							onClick={() => onDocsLinkClicked('AupViolation')}
						>
							{chunks}
						</a>
					),
				});
			case HandledErrorType.RateLimit:
				return intl.formatMessage(i18n[`${i18nPrefix}ErrorRateLimit`], {
					a: (chunks: React.ReactNode[]) => (
						<a
							href={USAGE_LIMITS_DOCS_URL}
							target="_blank"
							onClick={() => onDocsLinkClicked('RateLimit')}
						>
							{chunks}
						</a>
					),
				});
			default:
				return intl.formatMessage(i18n[`${i18nPrefix}ErrorGeneral`], {
					a: (chunks: React.ReactNode[]) => (
						<a
							href={TROUBLE_SHOOTING_DOCS_URL}
							target="_blank"
							onClick={() => onDocsLinkClicked('Troubleshooting')}
						>
							{chunks}
						</a>
					),
				});
		}
	};

	return (
		<Box xcss={textStyles}>
			<Text as="p">{errorMessage()}</Text>
			{footerCommentsError && shouldCallErrorDisplayForFooterComments && (
				<ErrorDisplay error={footerCommentsError} />
			)}
			{inlineCommentsError && shouldCallErrorDisplayForInlineComments && (
				<ErrorDisplay error={inlineCommentsError} />
			)}
		</Box>
	);
};
