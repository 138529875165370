export type ProductType =
	| 'confluence'
	| 'atlas'
	| 'jpd'
	| 'jsm'
	| 'jsw'
	| 'jwm'
	| 'bitbucket'
	| 'trello'
	| 'google'
	| 'rovo';

export type ExperienceIdType =
	| 'smart-link'
	| 'page-summaries'
	| 'content-catchup'
	| 'page-presentations'
	| 'whiteboard-summary';

export type PostAgentPayload = {
	recipient_agent_named_id: string;
	agent_input_prompt?: string;
	agent_input_context: AgentInputContext;
	user_intent?: string;
};

export type AgentInputContext = {
	content_url?: string;
	content_ari?: string;
	locale?: string;
	prompt_id?: string;
	summary_style?: SummaryStyle;
	summary_output_mimetype?: SummaryOutputMimeType;
	update_type?: string;
	output_mimetype?: SummaryOutputMimeType;
	json_structured?: boolean;
	original_content_version?: number;
	revised_content_version?: number;
};

export type SummaryStyle = 'short' | 'medium' | 'long';

export type SummaryOutputMimeType =
	| 'text/adf'
	| 'text/markdown'
	| 'text/json'
	| 'text/markdown-plus';

export enum ErrorMessage {
	'NETWORK_ERROR' = 'NETWORK_ERROR',
	'NO_ANSWER' = 'NO_ANSWER',
	'RATE_LIMIT' = 'RATE_LIMIT',
	// typo comes from the backend
	// https://bitbucket.org/atlassian/assistance-service/src/02087c3b6569f042ec338e08e8e0e152c4563dad/src/schema/message_publisher.py#lines-74
	'ANSWER_UNAVAILABLE_FOR_CONTENT' = 'ASWER_UNAVAILABLE_FOR_CONTENT',
	'NO_AGENT' = 'NO_AGENT',
	'PLUGIN_ERRORED' = 'PLUGIN_ERRORED',
	'OPENAI_RATE_LIMIT_USER_ABUSE' = 'OPENAI_RATE_LIMIT',
	'ACCEPTABLE_USE_VIOLATIONS' = 'ACCEPTABLE_USE_VIOLATIONS',
	'AI_DISABLED' = 'AI_DISABLED',
	'UNEXPECTED' = 'UNEXPECTED',
	'NOT_ENOUGH_CONTENT_ERROR' = 'NOT_ENOUGH_CONTENT_ERROR',
	'BAD_REQUEST' = 'BAD_REQUEST',
}

export type StreamMessage =
	| StreamTrace
	| StreamAnswerPart
	| StreamResponse
	| StreamError
	| StreamFollowUp;

export type StreamResponse = {
	type: 'FINAL_RESPONSE';
	message: {
		message: Message;
	};
	millisOffset?: number;
	metadata?: {
		request_id?: string;
	};
};

export type StreamError = {
	type: 'ERROR';
	message: {
		content: string;
		status_code?: number;
		message_template: ErrorMessage;
	};
	millisOffset?: number;
	metadata?: {
		error_message?: string;
		request_id?: string;
		timeout?: number;
	};
};

export type StreamAnswerPart = {
	type: 'ANSWER_PART';
	message: { content: string; role: 'ASSISTANT' };
	millisOffset: number;
	metadata?: {
		run_id?: string;
		request_id?: string;
	};
};

export type StreamTrace = {
	type: 'TRACE';
	message: { content: string };
	millisOffset: number;
	metadata?: {
		run_id: string;
		request_id: string;
		plugin_name?: string;
		plugin_input?: string;
	};
};

export type FollowUpObject = {
	type: string;
	text: string;
};

export type StreamFollowUp = {
	type: 'FOLLOW_UP_QUERIES';
	message: {
		follow_ups: FollowUpObject[] | null;
	};
	millisOffset: number;
	metadata?: { request_id: string };
};

export type Usage = {
	model_usage: {
		[key: string]: ModelUsage;
	};
	total: ModelUsage;
};

export type ModelUsage = {
	total_tokens: number;
	prompt_tokens: number;
	completion_tokens: number;
	request_count: number;
	total_cost: number;
	duration: number;
};

export type Durations = {
	events: {
		[key: string]: number;
	};
	total: number;
};

export type Message = {
	appendices?: Appendices;
	author?: {
		actor_type: string;
		ari: string | null;
		id: string;
		name: string;
		named_id: string;
	};
	author_id?: string;
	content: string;
	content_mime_type: ContentType;
	conversation_channel_id?: string;
	experience_id: ExperienceID;
	message_metadata?: MessageMetadata;
	plugin_invocations: PluginInvocationMessage[];
	role: Role;
	id: number;
	time_created: string;
	user_ari: string;
	sources?: Sources;
};

type MessageMetadata = {
	page_summary_id: string;
};

export type ContentType = 'text/markdown';

export type ExperienceID = 'ai-mate';

export type Role = 'ASSISTANT';

export type Appendices = Array<Appendix>;

export type Appendix = {
	type: 'requestForm' | 'helpDesk';
	content: string;
	appendix_sources?: Sources;
};

export type Sources = Array<Source>;

export type Source = {
	ari: string;
	title: string;
	type: string;
	url: string;
	lastModified: string;
	message_id: number;
	id: number;
};

export type PluginInvocation = {
	id: number | null;
	plugin_description: string | null;
	plugin_error_message: string | null;
	plugin_extra_info: string | null;
	plugin_input: string | null;
	plugin_invocation_duration: number | null;
	plugin_invocation_id: string | null;
	plugin_invocation_status: `PLUGIN_STARTED` | `PLUGIN_FINISHED` | null;
	plugin_invocation_time: string | null;
	plugin_name: string | null;
	plugin_output: string | null;
};

export type PluginInvocationMessage = PluginInvocation & {
	message_id: number | null;
};
