import { styled } from '@compiled/react';
import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl-next';

import { Stack } from '@atlaskit/primitives';
import type { PopupComponentProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';

import { i18n } from './i18n';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus': {
		outline: 'none',
	},
	borderRadius: `${token('border.radius.200')}`,
	boxShadow: `${token('elevation.shadow.overlay')}`,
	zIndex: 1,
});

export const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, ...props }, ref) => {
		const intl = useIntl();

		return (
			<PopupWrapper
				{...props}
				ref={ref}
				role="dialog"
				aria-label={intl.formatMessage(i18n.quickSummaryLabel)}
			>
				<Stack space="space.150">{children}</Stack>
			</PopupWrapper>
		);
	},
);
