import React from 'react';

import { defineMessages, useIntl } from 'react-intl-next';

import { Box, Stack, xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';

import { AtlassianIntelligenceLogo } from '../../common/assets/AtlassianIntelligenceLogo';

const i18n = defineMessages({
	generatingSummary: {
		id: 'ai-skeleton-loading.title',
		defaultMessage: 'Atlassian Intelligence is generating a summary...',
		description: 'loading text to notify that the summary is being generated',
	},
	logoAltText: {
		id: 'ai-skeleton-loading.logo-alt',
		defaultMessage: 'Atlassian Intelligence Logo',
		description: 'Atlassian Intelligence logo alt text',
	},
});

const loadingTextContainer = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.050',
	marginBottom: 'space.200',
	font: 'font.body.UNSAFE_small',
	color: 'color.text.subtle',
});

export const AISkeletonLoading = () => {
	const intl = useIntl();

	return (
		<Box padding="space.300">
			<Box xcss={loadingTextContainer}>
				<AtlassianIntelligenceLogo alt={intl.formatMessage(i18n.logoAltText)} />
				<div>{intl.formatMessage(i18n.generatingSummary)}</div>
			</Box>
			<Stack space="space.100">
				<Skeleton height={16} width={'100%'} borderRadius={8} isShimmering />
				<Skeleton height={16} width={'100%'} borderRadius={8} isShimmering />
				<Skeleton height={16} width={'100%'} borderRadius={8} isShimmering />
				<Skeleton height={16} width={'100%'} borderRadius={8} isShimmering />
			</Stack>
		</Box>
	);
};
