import type { InlineLinkingAgentResponse } from '../__types__/PageCatchupWrapperAIAgentStreamingTypes';

export const mapInlineLinkingAgentResponseToMarkdown = (
	responseContent: InlineLinkingAgentResponse,
) => {
	if (
		!responseContent.response ||
		responseContent.response.length === 0 ||
		(responseContent.response.length === 1 && Object.keys(responseContent.response[0]).length === 0)
	) {
		return '';
	}

	return responseContent.response
		.map((item) => {
			if ('heading' in item && 'changes' in item) {
				const changes =
					item.changes && item.changes.length > 0
						? item.changes
								.map((change) => {
									let formattedChange = '';
									if (change.change) {
										formattedChange = `* ${change.change}`;
										if (change.snippet) {
											formattedChange += ` ${change.snippet}`;
										}
									}
									return formattedChange;
								})
								.filter(Boolean)
								.join('\n')
						: '';
				return `### ${item.heading}\n${changes}\n`;
			}
			return '';
		})
		.join('\n');
};
