import { useEffect, useState } from 'react';

import { createSingleQueryParamHook } from '@confluence/route-manager';

const useOpenPageCatchupParam = createSingleQueryParamHook('openContentCatchup');

export const usePageCatchupQueryParamOpen = (
	isOpen: boolean,
	setIsOpen: (bool: boolean) => void,
	selectedVersions?: Set<any>,
) => {
	const [hasSeen, setHasSeen] = useState(false);
	const isOpenPageCatchupQueryParamValid = useOpenPageCatchupParam() === 'true';
	const hasSelectedVersions = selectedVersions && selectedVersions.size > 1;

	useEffect(() => {
		if (!isOpen && isOpenPageCatchupQueryParamValid && !hasSeen && hasSelectedVersions) {
			setIsOpen(true);
			setHasSeen(true);
		}
	}, [
		isOpen,
		setIsOpen,
		isOpenPageCatchupQueryParamValid,
		hasSeen,
		setHasSeen,
		hasSelectedVersions,
	]);
};
