import { useContext, useMemo } from 'react';
import type { ApolloError } from 'apollo-client';

import {
	COMMENTS_SUMMARY_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { markErrorAsHandled } from '@confluence/graphql';
import { CommentCountsContext } from '@confluence/comment-context';
import { HandledErrorType, useSummaryErrorHelper } from '@confluence/quick-summary';

import { CommentsType } from '../__types__/CommentsSummaryQuery';
import type { CommentsSummarySource } from '../CommentsSummaryContent';

type UseCommentsSummaryErrorHandlerData = {
	erredQueries: Array<CommentsType>;
	errorType: string;
	shouldCallErrorDisplayForFooterComments: boolean;
	shouldCallErrorDisplayForInlineComments: boolean;
};

export const useCommentsSummaryErrorHandler = (
	contentId: string,
	source: CommentsSummarySource,
	footerCommentsError?: ApolloError,
	inlineCommentsError?: ApolloError,
): UseCommentsSummaryErrorHandlerData => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { footerCommentsCount, unresolvedInlineCommentsCount } = useContext(CommentCountsContext);

	const { errorType: footerCommentsErrorType, isExpectedError: footerCommentsIsExpectedError } =
		useSummaryErrorHelper(COMMENTS_SUMMARY_EXPERIENCE, footerCommentsError);
	const { errorType: inlineCommentsErrorType, isExpectedError: inlineCommentsIsExpectedError } =
		useSummaryErrorHelper(COMMENTS_SUMMARY_EXPERIENCE, inlineCommentsError);

	const { errorType, commentsType } = useMemo(() => {
		let memoErrorType: HandledErrorType | undefined;
		let memoCommentsType: Array<CommentsType> = [];

		if (footerCommentsError && inlineCommentsError) {
			if (footerCommentsErrorType === inlineCommentsErrorType) {
				memoErrorType = footerCommentsErrorType;
			}
			memoCommentsType = [CommentsType.FOOTER, CommentsType.INLINE];
		} else if (footerCommentsError) {
			memoErrorType = footerCommentsErrorType;
			memoCommentsType = [CommentsType.FOOTER];
		} else if (inlineCommentsError) {
			memoErrorType = inlineCommentsErrorType;
			memoCommentsType = [CommentsType.INLINE];
		}

		return {
			errorType: memoErrorType || HandledErrorType.GeneralError,
			commentsType: memoCommentsType,
		};
	}, [inlineCommentsError, inlineCommentsErrorType, footerCommentsError, footerCommentsErrorType]);

	if (footerCommentsIsExpectedError) markErrorAsHandled(footerCommentsError);

	if (inlineCommentsIsExpectedError) markErrorAsHandled(inlineCommentsError);

	// Experience should only be a failure if both queries have erred
	// OR either query has failed when the other query was skipped due to 0 comments
	const isExperienceFailure =
		(footerCommentsError && (inlineCommentsError || unresolvedInlineCommentsCount === 0)) ||
		(inlineCommentsError && footerCommentsCount === 0);

	const isExpectedError =
		(footerCommentsIsExpectedError &&
			(inlineCommentsIsExpectedError || unresolvedInlineCommentsCount === 0)) ||
		(inlineCommentsIsExpectedError && footerCommentsCount === 0);

	if (isExperienceFailure) {
		if (isExpectedError) {
			experienceTracker.abort({
				name: COMMENTS_SUMMARY_EXPERIENCE,
				reason: `Can not generate summary due to ${errorType}`,
				attributes: {
					contentId,
					source,
				},
			});
		} else {
			// Experience tracker can only fail with a single error. Since both queries have failed we will use the footer
			// comments failure to end the experience and pass both types of failures as attributes for further debugging
			experienceTracker.fail({
				name: COMMENTS_SUMMARY_EXPERIENCE,
				// TS does not interpret the isExperienceFailure logic above correctly. Letting the compiler know
				error: (footerCommentsError || inlineCommentsError)!,
				attributes: {
					footerCommentsErrorType,
					inlineCommentsErrorType,
				},
			});
		}
	}

	return {
		erredQueries: commentsType,
		errorType,
		shouldCallErrorDisplayForFooterComments:
			!!footerCommentsError && !footerCommentsIsExpectedError,
		shouldCallErrorDisplayForInlineComments:
			!!inlineCommentsError && !inlineCommentsIsExpectedError,
	};
};
