import type { FC } from 'react';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Popup from '@atlaskit/popup';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip';

import { AIButtonIcon, CustomPopupContainer } from '@confluence/quick-summary';

import { PageCatchupComponentWithErrorBoundary as PageCatchupComponent } from './PageCatchupWrapperComponent';
import { usePageCatchupQueryParamOpen } from './usePageCatchupQueryParamOpen';

export const i18n = defineMessages({
	catchUpButtonText: {
		id: 'page-catchup.catchup.button.text',
		defaultMessage: 'Summarize differences',
		description: 'Text for Page Catchup popup button',
	},
});

export type PageCatchupPopupComponentProps = {
	contentId: string;
	contentType: string;
	spaceKey: string;
	source?: string;
	isDisabled?: boolean;
	selectedVersions?: Set<any>;
};

const ContentWidthStyles = xcss({
	width: '500px',
});

export const PageCatchupPopupComponent: FC<PageCatchupPopupComponentProps> = ({
	contentId,
	contentType,
	spaceKey,
	source,
	isDisabled,
	selectedVersions,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	usePageCatchupQueryParamOpen(isOpen, setIsOpen, selectedVersions);

	const onClick = () => {
		setIsOpen(!isOpen);

		if (!isOpen) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'pageCatchup',
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'pageCatchupButton',
					attributes: {
						contentId,
						source,
					},
				},
			}).fire();
		}
	};

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom-start"
			popupComponent={CustomPopupContainer}
			shouldFlip={false}
			shouldRenderToParent
			content={() => (
				<Box xcss={ContentWidthStyles}>
					<PageCatchupComponent
						contentId={contentId}
						contentType={contentType}
						spaceKey={spaceKey}
						source={source}
						selectedVersions={selectedVersions}
					/>
				</Box>
			)}
			trigger={(triggerProps) => (
				<Tooltip
					hideTooltipOnMouseDown
					position="bottom"
					content={intl.formatMessage(i18n.catchUpButtonText)}
				>
					<Button
						{...triggerProps}
						aria-haspopup="dialog"
						onClick={onClick}
						isSelected={isOpen}
						iconBefore={() => <AIButtonIcon label="" />}
						aria-label={intl.formatMessage(i18n.catchUpButtonText)}
						isDisabled={isDisabled}
					>
						{intl.formatMessage(i18n.catchUpButtonText)}
					</Button>
				</Tooltip>
			)}
		/>
	);
};
