import React, { type ReactNode, useState } from 'react';

import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import { N10 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const i18n = defineMessages({
	showMore: {
		id: 'ai-content-blur.show-more',
		defaultMessage: 'Show more',
		description: 'Button used to expand the summary section when clicked',
	},
});

const BodyStyles = xcss({
	overflow: 'hidden',
	position: 'relative',
});

const BlurStyles = xcss({
	position: 'absolute',
	background: `linear-gradient( 0deg, ${token('elevation.surface.overlay', N10)} 0%, ${token(
		'color.background.neutral.subtle',
		'rgba(255, 255, 255, 0)',
	)} 100% )`,
	height: '20px',
	width: '100%',
});

export type AIContentBlurProps = {
	children: ReactNode;
	onShowMore?: () => void;
	shouldOpenInCollapsedView?: boolean;
	summaryLineHeight?: number;
	padding?: string;
};

export const AIContentBlur = ({
	children,
	onShowMore,
	shouldOpenInCollapsedView,
	summaryLineHeight,
	padding,
}: AIContentBlurProps) => {
	const intl = useIntl();
	const [isCollapsed, setIsCollapsed] = useState(shouldOpenInCollapsedView);

	const handleShowMoreClick = () => {
		if (isCollapsed) {
			setIsCollapsed(false);
			onShowMore?.();
		}
	};

	if (!isCollapsed) {
		return <>{children}</>;
	}

	return (
		<Box testId="summary-blur-container" onClick={handleShowMoreClick} style={{ padding }}>
			<Flex alignItems="end" gap="space.050">
				<Inline grow="fill">
					<Box
						xcss={BodyStyles}
						style={{
							maxHeight: summaryLineHeight ? `${summaryLineHeight}px` : '40px',
						}}
						testId="child-text-box-container"
					>
						{children}
						<Box
							xcss={BlurStyles}
							style={{
								top: summaryLineHeight ? `${summaryLineHeight - 20}px` : '20px',
							}}
							testId="blur-styles-box-container"
						/>
					</Box>
				</Inline>
				<Button appearance="link" spacing="none">
					{intl.formatMessage(i18n.showMore)}
				</Button>
			</Flex>
		</Box>
	);
};
